export enum ColorEnum {
  GRAY = 'GRAY',
  BROW = 'BROW',
  ROSE = 'ROSE',
  INDIGO = 'INDIGO',
  CYAN = 'CYAN',
  TEAL = 'TEAL',
  LIME = 'LIME',
  RED = 'RED',
  ORANGE = 'ORANGE',
  ORANGE_DARK = 'ORANGE_DARK',
  GREEN = 'GREEN',
  GREEN_DARK = 'GREEN_DARK',
  BLUE = 'BLUE',
  BLUE_DARK = 'BLUE_DARK',
  PURPLE = 'PURPLE',
  PINK = 'PINK',
  PINK_DARK = 'PINK_DARK',
  YELLOW = 'YELLOW'
}
