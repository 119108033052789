<div (click)="onToggle()" class="group w-full h-7 flex items-center gap-1" [ngClass]="{ 'cursor-pointer': !disabled }">
  <div
    class="flex items-center"
    [ngClass]="{
      'border pl-3 pr-2 rounded-full': isBorder,
      'hover:border-blue-600': isBorder && !disabled,
      'opacity-80': disabled
    }">
    @if (label) {
      <span class="font-semibold min-w-max">{{ label }}: </span>
    }

    <div
      class="w-full flex justify-between items-center px-2 py-1 h-7 rounded"
      [ngClass]="{
        'hover:bg-neutral-alpha-100A': !disabled && !isBorder
      }">
      @if (userSelected) {
        <div class="flex items-center gap-1">
          <app-custom-avatar [imgId]="userSelected?.avatar || ''" [name]="userSelected.fullName"></app-custom-avatar>
          <span class="w-max" [ngClass]="isHighlightName ? 'font-medium' : ''">{{ userSelected?.fullName }}</span>
        </div>
      } @else {
        <span [ngClass]="{ 'text-neutral-alpha-500A': !isBorder }">
          {{ placeholder || ('common.empty' | translate) }}
        </span>
      }
      @if (!disabled) {
        <i
          *ngIf=""
          class="icon-wl-arrow-drop-down text-6"
          [ngClass]="visible ? '' : 'group-hover:opacity-100 opacity-0'"></i>
      }
    </div>
  </div>
</div>

<p-overlay
  [appendTo]="appendTo"
  [(visible)]="visible"
  (onHide)="onHide()"
  contentStyleClass="rounded-md shadow bg-white w-full">
  <div class="h-11 flex items-center gap-2 border-b">
    <app-search-input
      inputClass="border-none !shadow-none"
      [debounceTime]="300"
      (onSearch)="onSearch($event)"></app-search-input>
  </div>
  <div class="relative">
    <div
      class="w-full max-h-[200px] overflow-y-auto relative"
      [ngClass]="{ 'pb-11': isApplyBtn }"
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      [scrollWindow]="false"
      (scrolled)="onScroll()">
      @if (users.length) {
        @for (user of users; track user.id) {
          <div
            class="flex items-center px-3 hover:bg-neutral-200 py-2 gap-2 cursor-pointer"
            (click)="onSelectLead(user)">
            <div class="h-5 min-w-5 flex items-center justify-center">
              @if (user.id === tempSelected?.id) {
                <i class="icon-wl-check text-6 text-primary-600"></i>
              }
            </div>
            <app-custom-avatar [imgId]="user?.avatar ?? ''" [name]="user?.fullName ?? ''"></app-custom-avatar>
            <div class="w-full truncate">
              {{ user.fullName }}
            </div>
          </div>
        }
      } @else {
        <div class="flex items-center justify-center flex-col gap-2 py-4">
          <div class="h-9 w-9 flex items-center justify-center bg-neutral-alpha-200A rounded-full">
            <i class="icon-wl-user text-6"></i>
          </div>

          <div>{{ 'common.no-users-to-display' | translate }}</div>
        </div>
      }
    </div>
    @if (isApplyBtn) {
      <div class="absolute bottom-0 w-full bg-white flex justify-end border-t py-2 px-4">
        <app-button size="xs" [label]="'action.apply' | translate" (onClick)="onApplyClick()" />
      </div>
    }
  </div>
</p-overlay>
