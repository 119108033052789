import { ColorEnum } from '@core/enum/common';
import { version as appVersion } from '../../../../package.json';

export const APP_VERSION = appVersion;

export const Colors = [
  {
    value: ColorEnum.GRAY,
    primaryColor: '#626F86',
    textColor: '#44546F',
    bgColor: '#F1F2F4'
  },
  {
    value: ColorEnum.BROW,
    primaryColor: '#9C6644',
    textColor: '#6B4135',
    bgColor: '#EFE6D8'
  },
  {
    value: ColorEnum.RED,
    primaryColor: '#EF4444',
    textColor: '#991B1B',
    bgColor: '#FEE2E2'
  },
  {
    value: ColorEnum.ORANGE,
    primaryColor: '#F97316',
    textColor: '#9A3412',
    bgColor: '#FFEDD5'
  },
  {
    value: ColorEnum.ORANGE_DARK,
    primaryColor: '#D97708',
    textColor: '#92400D',
    bgColor: '#FEF3C7'
  },
  {
    value: ColorEnum.YELLOW,
    primaryColor: '#D19500',
    textColor: '#89530A',
    bgColor: '#FFFEC1'
  },
  {
    value: ColorEnum.LIME,
    primaryColor: '#84CC16',
    textColor: '#3F6212',
    bgColor: '#ECFCCB'
  },
  {
    value: ColorEnum.GREEN,
    primaryColor: '#22C55E',
    textColor: '#166534',
    bgColor: '#DCFCE7'
  },
  {
    value: ColorEnum.GREEN_DARK,
    primaryColor: '#079669',
    textColor: '#076046',
    bgColor: '#D1FAE5'
  },
  {
    value: ColorEnum.TEAL,
    primaryColor: '#14B8A6',
    textColor: '#115E59',
    bgColor: '#CCFBF1'
  },
  {
    value: ColorEnum.CYAN,
    primaryColor: '#06B6D4',
    textColor: '#155E75',
    bgColor: '#CFFAFE'
  },
  {
    value: ColorEnum.BLUE_DARK,
    primaryColor: '#0084C7',
    textColor: '#065985',
    bgColor: '#E0F2FE'
  },
  {
    value: ColorEnum.BLUE,
    primaryColor: '#3B82F6',
    textColor: '#1E40AF',
    bgColor: '#DBEAFE'
  },
  {
    value: ColorEnum.INDIGO,
    primaryColor: '#6366F1',
    textColor: '#3032A3',
    bgColor: '#E0E1FF'
  },
  {
    value: ColorEnum.PURPLE,
    primaryColor: '#8B5CF6',
    textColor: '#5B21B6',
    bgColor: '#EDE9FE'
  },
  {
    value: ColorEnum.PINK_DARK,
    primaryColor: '#C125D3',
    textColor: '#861A8F',
    bgColor: '#FAE8FF'
  },
  {
    value: ColorEnum.PINK,
    primaryColor: '#EC4899',
    textColor: '#9D174D',
    bgColor: '#FCE7F3'
  },
  {
    value: ColorEnum.ROSE,
    primaryColor: '#F43F5E',
    textColor: '#9F1239',
    bgColor: '#FFE4E6'
  }
];
